import React from 'react';
import { getCsRights } from '@src/utils/helpers/wsCalls';
import { getUserId } from '@src/utils/helpers/authentication';

function withRequiresAdmin(WrappedComponent, NotAdminComponent) {
  return class extends React.Component {
    texts = {
      loading: 'loading...',
      notAdmin: 'You are not an admin'
    };

    constructor(props) {
      super(props);
      this.state = {
        isAdmin: undefined
      };
    }

    componentDidMount() {
      const fulfilledGetRights = data => {
        const isAdmin = data.rights.includes('EDIT_SYSTEM_FOOD');
        this.setState({
          isAdmin
        });
      };

      const rejectedGetRights = () => {
        this.setState({
          isAdmin: false
        });
      };

      getCsRights(fulfilledGetRights, rejectedGetRights, getUserId());
    }

    render() {
      const { isAdmin } = this.state;
      const { texts } = this;
      return (
        <>
          {!!isAdmin && <WrappedComponent {...this.props} />}
          {isAdmin === undefined && <div>{texts.loading}</div>}
          {isAdmin === false && <NotAdminComponent />}
        </>
      );
    }
  };
}

export default withRequiresAdmin;

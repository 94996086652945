// temporary using old articleSections

import { connect } from 'react-redux';
import { articleSectionsActions } from '@ducks/articleSections';
import ArticlesList from './ArticlesList';

const mapStateToProps = state => ({
  hasSubscription: state.user.hasSubscription,
  isLoggedIn: state.user.isLoggedIn,
  articlesLists: state.articleSections.lists,
  salesposters: state.articleSections.salesposters,
  articleSearchPage: state.articleSections.articleSearchPage,
  getSectionFulfilled: state.articleSections.fulfilled,
  getSectionPending: state.articleSections.pending,
  error: state.articleSections.error,
  product: state.config.product
});

const mapDispatchToProps = dispatch => ({
  getPaywallArticlesList: (
    id,
    articleSearchPage,
    articlesNumber,
    searchParamPage
  ) => {
    dispatch(
      articleSectionsActions.getPaywallArticlesList(
        id,
        articleSearchPage,
        articlesNumber,
        '',
        searchParamPage
      )
    );
  },
  getSalesArticlesList: (
    id,
    articleSearchPage,
    articlesNumber,
    searchParamPage
  ) => {
    dispatch(
      articleSectionsActions.getSalesArticlesList(
        id,
        articleSearchPage,
        articlesNumber,
        '',
        searchParamPage
      )
    );
  },
  clearArticleList: () => {
    dispatch(articleSectionsActions.clearArticleSections());
  }
});

const articleContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticlesList);

export default articleContainer;

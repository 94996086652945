import { connect } from 'react-redux';
import { articleActions } from '@ducks/article';
import Article from './Article';

const mapStateToProps = (state, ownProps) => {
  const articleData = ownProps?.id?.includes(state.article?.id)
    ? {
        title: state.article.title,
        lead: state.article.lead,
        topImage: state.article.topImage,
        topEmbed: state.article.topEmbed,
        bodyContent: state.article.bodyContent,
        salesposters: state.article.salesposters,
        authors: state.article.authors,
        categories: state.article.categories,
        tags: state.article.tags,
        published: state.article.published,
        updated: state.article.updated,
        topVideo: state.article.topVideo,
        canonicalUrl: state.article.canonicalUrl,
        metaData: state.article.metaData,
        isPaid: state.article.isPaid,
        requestStatus: state.article.requestStatus,
        noindex: state.article.noindex
      }
    : {};
  const userData = {
    isLoggedIn: state.user.isLoggedIn,
    hasSubscription: state.user.hasSubscription
  };
  const productData = {
    product: state.config.product
  };
  const requestStatus = {
    requestStatus: state.article.requestStatus,
    error: state.article.error
  };
  return {
    ...articleData,
    ...userData,
    ...productData,
    ...requestStatus
  };
};

const mapDispatchToProps = dispatch => ({
  getArticle: (isWellobe, id) => {
    dispatch(articleActions.getArticle(isWellobe, id));
  },
  getNonMemberArticle: (isWellobe, id) => {
    dispatch(articleActions.getNonMemberArticle(isWellobe, id));
  },
  clearArticle: () => {
    dispatch(articleActions.clearArticle());
  }
});

const articleContainer = connect(mapStateToProps, mapDispatchToProps)(Article);

export default articleContainer;

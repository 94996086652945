import moment from 'moment';
import bmiCalc from '@src/utils/helpers/bmiCalc';
import {
  isItWellobe as isWellobe,
  DEFAULT_PRODUCT
} from '@src/utils/helpers/isWellobe';

const MIN_BMI = 20;

export const isValidBMI = (height = 1, weight = 1) => {
  const roundedBmi = bmiCalc.getRoundedBmi(weight, height);

  return !(roundedBmi < MIN_BMI);
};

export const isValidTargetWeight = (currentWeight, targetWeight) =>
  targetWeight < currentWeight;

export const calcBMI = (height = 1, weight = 1) =>
  bmiCalc.getRoundedBmi(weight, height);

// eslint-disable-next-line prefer-template
export const formatBMI = bmi => (bmi + '').replace('.', ',');

export const isEligableToLooseWeight = (
  height = 1,
  currentWeight = 1,
  targetWeight = 1
) => {
  const isTargetBmiOK = isValidBMI(height, targetWeight);
  const isAimingToLoosWeight = isValidTargetWeight(currentWeight, targetWeight);

  return isTargetBmiOK && isAimingToLoosWeight;
};

export const getGoalDate = (today, currentWeight, targetWeight) => {
  const mToday = moment(today);
  const weightDelta = currentWeight - targetWeight;
  const goalDate = mToday.add(weightDelta, 'weeks');

  return goalDate;
};

export const getDateFormat = (product = DEFAULT_PRODUCT) => {
  let dateFormat = 'D MMMM YYYY';

  if (!isWellobe(product)) {
    dateFormat = 'D. MMMM YYYY';
  }

  return dateFormat;
};
